<section class="flex max-w-[982px] flex-col">
  <h2 class="mt-4 text-sm font-bold leading-tight text-black max-md:max-w-full">Equivalencia en créditos:</h2>
  <ul class="d mt-5 w-full text-sm leading-5 text-black max-md:max-w-full">
    <li class="font-bold">• Conexión con talento Tribuu = 1 crédito</li>
    <li class="mt-4 font-bold">
      • Insignias:
      <ul class="ml-5 font-normal">
        <li>- Insignia Triber Verificado = 3 créditos</li>
      </ul>
    </li>
  </ul>
  <h2 class="mt-5 text-sm font-bold leading-tight text-black max-md:max-w-full">Tabla de opciones:</h2>
  <div class="mt-5 flex w-[590px] max-w-full flex-col">
    <form [formGroup]="creditsForm" class="relative" (ngSubmit)="buyCredits()">

        <label
          [ngClass]="{
            'border-primary': creditsForm.value.amount === 0,
            'pointer-events-none opacity-40': isFreeTrialExchanged(),
          }"
          for="free-trial"
          class="flex min-h-[42px] w-full flex-col justify-center rounded-xl border border-solid border-gray-500 px-5 py-3 text-sm leading-tight text-black max-md:max-w-full">
          <div class="flex w-full flex-wrap items-center justify-between gap-10 max-md:max-w-full">
            <p class="my-auto self-stretch">Primeros 5 créditos <strong>GRATIS</strong> (30 días)</p>
            <div
              class="relative my-auto flex aspect-[0.944] min-h-[18px] w-[17px] flex-col items-center justify-center self-stretch py-1.5 pl-1 pr-1.5">
              <img
                loading="lazy"
                src="/assets/images/forms/empty-check.png"
                alt=""
                class="absolute inset-0 size-full object-cover" />
              <img
                [ngClass]="{
                  '!inline-block': creditsForm.value.amount === 0,
                }"
                loading="lazy"
                src="/assets/images/forms/full-check.png"
                alt="20% descuento icon"
                class="hidden aspect-[1.14] w-full object-contain transition-all duration-300 ease-in-out" />
            </div>
          </div>
        </label>
        <input
          id="free-trial"
          formControlName="amount"
          type="radio"
          class="absolute opacity-0"
          name="amount"
          [value]="0" />


      <ng-container *ngFor="let price of buyCreditsOpts">
        <label
          [ngClass]="{
            'border-primary': creditsForm.value.amount === price.quantity,
          }"
          for="{{ price.quantity }}b"
          class="mt-2.5 flex min-h-[42px] w-full cursor-pointer flex-col justify-center rounded-xl border border-solid border-gray-500 px-5 py-3 max-md:max-w-full">
          <div class="flex w-full flex-wrap items-center justify-between gap-10 max-md:max-w-full">
            <p class="my-auto flex min-w-[240px] items-center gap-1.5 self-stretch leading-tight">
              <span class="text-sm text-black"
                >{{ price.quantity }} créditos = ${{ price.total | money: 0 }} MXN</span
              >
              <span class="text-secondary text-xs font-bold" *ngIf="price.discount"
                >{{ price.discount }}% de descuento</span
              >
            </p>
            <div
              class="relative my-auto flex aspect-[0.944] min-h-[18px] w-[17px] flex-col items-center justify-center self-stretch py-1.5 pl-1 pr-1.5">
              <img
                loading="lazy"
                src="/assets/images/forms/empty-check.png"
                alt=""
                class="absolute inset-0 size-full object-cover" />
              <img
                [ngClass]="{
                  '!inline-block': creditsForm.value.amount === price.quantity,
                }"
                loading="lazy"
                src="/assets/images/forms/full-check.png"
                alt="20% descuento icon"
                class="hidden aspect-[1.14] w-full object-contain transition-all duration-300 ease-in-out" />
            </div>
          </div>
        </label>
        <input
          id="{{ price.quantity }}b"
          formControlName="amount"
          type="radio"
          class="absolute opacity-0"
          name="amount"
          [value]="price.quantity" />
      </ng-container>

      <p class="mt-2 text-gray-500">Entrevista = $9.9 USD aprox.</p>
      <div class="mt-5 block w-full text-center">
        <button
          tribuuButton
          type="submit"
          [theme]="buttonColor"
          [disabled]="creditsForm.disabled"
          class="mt-3 !min-w-[250px] !text-lg">
          Comprar
        </button>
      </div>
    </form>
  </div>
</section>
