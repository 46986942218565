import { Injectable, inject } from '@angular/core';
import { PatchFormResume } from '@modules/account/data/classes/form-create-resume';
import { Action, NgxsOnInit, Selector, State, StateContext, createSelector } from '@ngxs/store';
import { ResumeAllRelationsDto, ResumesApi } from '@tribuu-api';
import { tap } from 'rxjs';
import { ResumeActions } from './resume.actions';

export interface ResumeStateModel {
  list: { [key: string]: ResumeAllRelationsDto };
  draft?: Partial<PatchFormResume> | null;
  loaded: boolean;
  loading: boolean;
}

@State<ResumeStateModel>({
  name: 'resume',
  defaults: {
    list: {},
    draft: null,
    loaded: false,
    loading: false,
  },
})
@Injectable()
export class ResumeState implements NgxsOnInit {
  private resumeApi = inject(ResumesApi);

  @Selector()
  static list(resumeState: ResumeStateModel): ResumeAllRelationsDto[] {
    const resumes = Object.values(resumeState.list);
    return resumes;
  }

  @Selector()
  static resumeDrafted(state: ResumeStateModel) {
    return state.draft;
  }

  static resumeById(uid: string) {
    return createSelector([ResumeState], (state: ResumeStateModel) => state.list[uid]);
  }

  ngxsOnInit(ctx: StateContext<any>): void {
    ctx.dispatch(new ResumeActions.FetchAll());
  }

  @Action(ResumeActions.UpdateDraft)
  updateDraft(ctx: StateContext<ResumeStateModel>, { resume }: ResumeActions.UpdateDraft) {
    ctx.patchState({ draft: resume });
  }

  @Action(ResumeActions.SaveDraft)
  saveDraft(ctx: StateContext<ResumeStateModel>, { resume }: ResumeActions.SaveDraft) {
    
    return this.resumeApi.updateResumeDraft({ updateResumeProfileDto: resume }).pipe(tap(() => {
      ctx.patchState({ draft: resume });
    })); // if changes save draft on database
  }

  @Action(ResumeActions.FindOne)
  findOne(ctx: StateContext<ResumeStateModel>, { uid }: ResumeActions.FindOne) {
    const resumesMap = ctx.getState().list;
    const loaded = ctx.getState().loaded;
    // If the resume with ID has been already loaded
    // we just break the execution
    if (!resumesMap[uid] || !loaded) return;
    return this.resumeApi.firstResume({ uid }).pipe(
      tap((resume) => {
        ctx.patchState({
          list: { ...resumesMap, [resume.uid]: resume },
        });
      })
    );
  }

  @Action(ResumeActions.FetchAll)
  fetchAll(ctx: StateContext<ResumeStateModel>) {
    ctx.patchState({ loading: true });
    const list = ctx.getState().list;
    return this.resumeApi.searchResumes({ limit: 20 }).pipe(
      tap((page) => {
        const pageItems = page.items.reduce(
          (acc, item) => ({ ...acc, ...{ [item.uid]: item } }),
          {}
        );
        ctx.patchState({
          list: { ...list, ...pageItems },
          loaded: true,
          loading: false,
        });
      })
    );
  }
}
