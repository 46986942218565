import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import { BuyCreditsComponent } from '../buy-credits/buy-credits.component';

@Component({
  selector: 'app-modal-buy-credits',
  templateUrl: './modal-buy-credits.component.html',
  standalone: true,
  imports: [BuyCreditsComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalBuyCreditsComponent {
  private readonly context = inject(POLYMORPHEUS_CONTEXT);
  isFreeTrialExchanged = this.context.data;

  onBuyCreditChange(data: { amount: number; location: string } | null): void {
    this.context.completeWith(data);
  }
}
