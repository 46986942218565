export const PRICES: IPrice[] = [
  {
    quantity: 1,
    amount: 200,
    discount: 0,
    total: 200,
  },
  {
    quantity: 2,
    amount: 400,
    discount: 0,
    total: 400,
  },
  {
    quantity: 3,
    amount: 600,
    discount: 0,
    total: 600,
  },
  {
    quantity: 5,
    amount: 1000,
    discount: 0,
    total: 1000,
  },
  {
    quantity: 10,
    amount: 2000,
    discount: 10,
    total: 1800,
  },
  {
    quantity: 15,
    amount: 2400,
    discount: 20,
    total: 2400,
  },
  {
    quantity: 30,
    amount: 6000,
    discount: 33,
    total: 4000,
  },
  {
    quantity: 50,
    amount: 10000,
    discount: 40,
    total: 6000,
  },
];

interface IPrice {
  quantity: number;
  amount: number;
  discount: number;
  total: number
}
