import { NgClass, NgFor, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
} from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AlertService } from '@core/services/ui/alert.service';
import { PaymentsState } from '@core/store/payments/payments.state';
import { PRICES } from '@data/constants/config/prices.config';
import { Store } from '@ngxs/store';
import { TribuuButtonDirective } from '@shared/directives/tribuu-button.directive';
import { required } from '@shared/helpers/validators.helper';
import { MoneyPipe } from '@shared/pipes/money.pipe';

@Component({
  selector: 'app-buy-credits',
  templateUrl: './buy-credits.component.html',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgClass,
    NgFor,
    NgIf,
    MoneyPipe,
    TribuuButtonDirective,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BuyCreditsComponent {
  private readonly store = inject(Store);
  @Input() buttonColor: 'dark' | 'primary' = 'dark';
  // @Input() isFreeTrialExchanged = this.store.selectSignal(PaymentsState.isFreeCreditsAlreadyClaimed);
  @Output() onActivateFreeTrial = new EventEmitter();
  @Output() onBuyCredits = new EventEmitter();

  isFreeTrialExchanged = this.store.selectSignal(PaymentsState.isFreeCreditsAlreadyClaimed);

  private readonly alertService = inject(AlertService);

  //TODO: mover a archivos de configuracion o modulo de constantes
  readonly buyCreditsOpts = PRICES;

  creditsForm = new FormGroup({
    amount: new FormControl<number>(1, {
      nonNullable: true,
      validators: [required],
    }),
  });

  buyCredits() {
    // TODO: check if form is valid before to send request
    const { amount } = this.creditsForm.getRawValue();
    this.creditsForm.disable();

    if (amount === 0) return this.activateFreeTrial();

    this.creditsForm.enable();
    this.onBuyCredits.emit({ amount, location: window.location.href });
  }

  activateFreeTrial() {
    this.alertService.success('Ya dispones de tus créditos para conectar!', 'Bien');
    this.creditsForm.enable();
    this.onActivateFreeTrial.emit();
  }
}
