import { inject, Injectable, signal } from '@angular/core';
import { Router } from '@angular/router';
import { CreditsApi, PaymentApi } from '@tribuu-api';
import { ConfettiService } from '@core/services/ui/confetti.service';
import { INTERNAL_ROUTES } from '@data/constants/routes';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ModalBuyCreditsComponent } from '@shared/components/modal-buy-credits/modal-buy-credits.component';
import { TuiDialogService } from '@taiga-ui/core';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { IPromptData, PromptComponent } from '@ui/dialogs/prompt/prompt.component';
import { addDays, isPast } from 'date-fns';
import { catchError, firstValueFrom, lastValueFrom, map, of, tap, throwError } from 'rxjs';
import { ReservationActions } from '../reservations/reservations.actions';
import { PaymentActions } from './payments.actions';

export interface PaymentsStateModel {
  boughtCredits: number;
  freeCredits: number;
  freeCreditsExpiredAt: Date | null;
  transactions: Array<any>;
  loaded: boolean;
  loading: boolean;
}

const DEFAULT_STATE: PaymentsStateModel = {
  boughtCredits: 0,
  freeCredits: 0,
  freeCreditsExpiredAt: null,
  transactions: [],
  loaded: false,
  loading: false,
};

@State<PaymentsStateModel>({
  name: 'payments',
  defaults: DEFAULT_STATE,
})
@Injectable()
export class PaymentsState {
  readonly creditsAPI = inject(CreditsApi);
  readonly paymentsAPI = inject(PaymentApi);
  readonly dialogs = inject(TuiDialogService);
  readonly confetti = inject(ConfettiService);
  private readonly router = inject(Router);

  constructor() {}

  @Selector()
  static isFreeCreditsAlreadyClaimed(state: PaymentsStateModel) {
    return Boolean(state.freeCreditsExpiredAt);
  }

  @Selector()
  static isFreeCreditsExpired({ freeCreditsExpiredAt }: PaymentsStateModel) {
    if (!freeCreditsExpiredAt) return false;

    return isPast(freeCreditsExpiredAt);
  }

  @Selector()
  static totalCredits(state: PaymentsStateModel) {
    return state.freeCredits + state.boughtCredits;
  }

  @Selector()
  static freeCredits(state: PaymentsStateModel) {
    return state.freeCredits;
  }

  @Selector()
  static boughtCredits(state: PaymentsStateModel) {
    return state.boughtCredits;
  }

  @Action(PaymentActions.FetchCreditsDetails)
  fetchCreditsDetails(ctx: StateContext<PaymentsStateModel>) {
    return this.creditsAPI.authUserCreditDetails().pipe(
      tap(({ freeCredits, credits, freeCreditsExpiredAt }) => {
        ctx.patchState({ freeCredits, boughtCredits: credits, freeCreditsExpiredAt });
      })
    );
  }

  @Action(PaymentActions.RefundCredit)
  refundCredit(ctx: StateContext<PaymentsStateModel>, { data }: PaymentActions.RefundCredit) {
    return ctx.patchState({
      freeCredits: data.freeCredits,
      boughtCredits: data.credits,
      freeCreditsExpiredAt: data.freeCreditsExpiredAt,
    });
  }

  @Action(PaymentActions.ResumeServicePayment)
  async resumeServicePayment(
    ctx: StateContext<PaymentsStateModel>,
    {
      params: {
        paymentDto: { startAt, jobId, templateId },
        uid,
      },
    }: PaymentActions.ResumeServicePayment
  ) {
    const { boughtCredits, freeCredits, freeCreditsExpiredAt } = ctx.getState();
    const template = new PolymorpheusComponent(PromptComponent);
    const totalCredits = boughtCredits + freeCredits;
    if (!totalCredits) {
      if (freeCreditsExpiredAt) {
        /// already claimed free credits, offer buy more
        ctx.patchState({ loading: false });
        this.dialogs
          .open(new PolymorpheusComponent(ModalBuyCreditsComponent), {
            data: signal(freeCreditsExpiredAt),
          })
          .subscribe((data: any) => {
            if (data) {
              ctx.dispatch(new PaymentActions.CreditsPurchase(data.amount, data.location));
              return;
            }
            ctx.dispatch(new PaymentActions.ClaimFreeCredits());
          });
        return;
      }
      const wantsToClaimFreeCredits = await lastValueFrom(
        this.dialogs.open<boolean>(template, {
          required: true,
          data: {
            title: 'No tienes créditos disponibles',
            content: '¿Deseas activar la prueba gratuita? Puedes canjearla en cualquier momento.',
            yes: 'Sí, canjear',
            no: 'En otra ocasión',
          },
        })
      );

      if (wantsToClaimFreeCredits) {
        try {
          // confirm exchange
          ctx.patchState({ loading: true });
          const claim = await firstValueFrom(this.creditsAPI.claimFreeTrial());
          ctx.patchState({ freeCredits: 5, freeCreditsExpiredAt: addDays(new Date(), 30) });
          this.confetti.fireConfetti();

          ctx.dispatch(
            new PaymentActions.ResumeServicePayment({
              paymentDto: { startAt, jobId, templateId },
              uid,
            })
          );
          return;
        } catch (error) {}
      } else {
        this.router.navigateByUrl(INTERNAL_ROUTES.APP_PRICES_PAGE);
        return;
      }
    }

    try {
      /// modal confirmation
      const data: IPromptData = {
        title: `Tienes ${totalCredits} créditos disponibles`,
        content: 'Se te descontará 1 crédito para la entrevista. ¿deseas continuar?',
        centerButtons: true,
        centerContent: true,
        centerTitle: true,
        separator: true,
        yes: 'Sí, aceptar',
        no: 'No, cancelar',
      };
      const template = new PolymorpheusComponent(PromptComponent);
      const confirmExchange = await firstValueFrom(
        this.dialogs.open<boolean>(template, { data, size: 's' })
      );
      if (!confirmExchange) {
        return;
      }
      ctx.patchState({ loading: true });
      await firstValueFrom(
        this.paymentsAPI.buyResumeWithCredits({
          uid,
          paymentDto: {
            startAt,
            templateId,
            jobId,
          },
        })
      );
      ctx.dispatch(
        new ReservationActions.SuccesPayment({
          message: 'Ir a mis reservas',
          redirect: INTERNAL_ROUTES.APP_ACCOUNT_RESERVATIONS,
        })
      );
      ctx.patchState({ loading: false });
    } catch (error) {
      ctx.patchState({ loading: false });
    }
  }
  /// todo: DRY
  @Action(PaymentActions.PublicationServicePayment)
  async publicationServicePayment(
    ctx: StateContext<PaymentsStateModel>,
    { params: { publicationId, startAt, jobId } }: PaymentActions.PublicationServicePayment
  ) {
    const { boughtCredits, freeCredits, freeCreditsExpiredAt } = ctx.getState();
    const template = new PolymorpheusComponent(PromptComponent);
    const totalCredits = boughtCredits + freeCredits;
    if (!totalCredits) {
      if (freeCreditsExpiredAt) {
        /// already claimed free credits, offer buy more
        ctx.patchState({ loading: false });

        this.dialogs
          .open(new PolymorpheusComponent(ModalBuyCreditsComponent), {
            data: signal(freeCreditsExpiredAt),
          })
          .subscribe((data: any) => {
            if (data) {
              ctx.dispatch(new PaymentActions.CreditsPurchase(data.amount, data.location));
              return;
            }
            ctx.dispatch(new PaymentActions.ClaimFreeCredits());
          });

        return;
      }
      const wantsToClaimFreeCredits = await lastValueFrom(
        this.dialogs.open<boolean>(template, {
          size: 's',
          required: true,
          data: {
            title: 'No tienes créditos disponibles',
            content: '¿Deseas activar la prueba gratuita? Puedes canjearla en cualquier momento.',
            yes: 'Sí, canjear',
            no: 'En otra ocasión',
          },
        })
      );

      if (wantsToClaimFreeCredits) {
        try {
          // confirm exchange
          ctx.patchState({ loading: true });
          const claim = await firstValueFrom(this.creditsAPI.claimFreeTrial());
          ctx.patchState({ freeCredits: 5, freeCreditsExpiredAt: addDays(new Date(), 30) });
          this.confetti.fireConfetti();

          ctx.dispatch(
            new PaymentActions.PublicationServicePayment({
              publicationId,
              startAt,
              jobId,
            })
          );
          return;
        } catch (error) {}
      } else {
        this.router.navigateByUrl(INTERNAL_ROUTES.APP_PRICES_PAGE);
        return;
      }
    }

    try {
      /// modal confirmation
      const data: IPromptData = {
        title: `Tienes ${totalCredits} crédito${totalCredits > 1 ? 's' : ''} disponible${
          totalCredits > 1 ? 's' : ''
        }`,
        content: 'Se te descontará 1 crédito para la sesión. ¿deseas continuar?',
        centerContent: true,
        centerTitle: true,
        separator: true,
        yes: 'Sí, aceptar',
        no: 'No, cancelar',
      };
      const template = new PolymorpheusComponent(PromptComponent);
      const confirmExchange = await firstValueFrom(
        this.dialogs.open<boolean>(template, { data, closeable: false, dismissible: false })
      );
      if (!confirmExchange) {
        return;
      }
      ctx.patchState({ loading: true });
      await firstValueFrom(
        this.paymentsAPI.checkout({ createCheckoutDto: { publicationId, startAt, jobId } })
      );
      ctx.dispatch(
        new ReservationActions.SuccesPayment({
          message: 'Ir a mis reservas',
          redirect: INTERNAL_ROUTES.APP_ACCOUNT_RESERVATIONS,
        })
      );
      ctx.patchState({ loading: false });
    } catch (error) {
      ctx.patchState({ loading: false });
    }
  }

  @Action(PaymentActions.CreditsPurchase)
  creditsPurchase(
    ctx: StateContext<PaymentsStateModel>,
    { amount, redirectCancel }: PaymentActions.CreditsPurchase
  ) {
    return this.paymentsAPI
      .creditsPurchase({
        buyCreditsParams: {
          amount,
          redirectCancel,
        },
      })
      .pipe(
        tap((redirect) => {
          const handle = open(redirect.url, '_self', 'noopener,noreferrer');
          if (!handle) {
            return;
          }
        })
      );
  }

  @Action(PaymentActions.ClaimFreeCreditsSuccess)
  claimFreeCreditsSuccess(ctx: StateContext<PaymentsStateModel>) {
    this.confetti.fireConfetti();
    ctx.patchState({
      loading: false,
      freeCreditsExpiredAt: addDays(new Date(), 30),
      freeCredits: 5,
    });
  }

  @Action(PaymentActions.ClaimFreeCredits)
  claimFreeCredits(ctx: StateContext<PaymentsStateModel>) {
    const { freeCreditsExpiredAt } = ctx.getState();
    if (freeCreditsExpiredAt) return of(false); /// already claimed, no need to claim
    return this.creditsAPI.claimFreeTrial().pipe(
      tap(() => ctx.dispatch(new PaymentActions.ClaimFreeCreditsSuccess())),
      catchError(() => {
        ctx.patchState({ loading: false, freeCredits: 0, freeCreditsExpiredAt: null }); /// reset
        return throwError(() => new Error('Error al canjear créditos'));
      }),
      map(() => true)
    );
  }
}
